<template>
  <header>
    <img
            class="back"
            @click="back"
            src="@/assets/img/leftBack.svg"
            alt=""
    />
    <div class="title">餐厅投票</div>
    <h1 class="logo">
      <a href="javascript:void(0);">
        {{ userInfo.avatar }}
        <img
                style="border-radius: 50%"
                src="https://beinabncc.oss-cn-beijing.aliyuncs.com/News/72bd4bed20c9be16bbe779d66699e17c.jpg"
                alt=""
                v-if="!userInfo.userImg"
        />
        <img style="border-radius: 50%" :src="userInfo.userImg" alt="" v-else />
      </a>
    </h1>
  </header>
  <main>
    <div class="search">
      <select class="challengeWhellSelect" v-model="yearSelected">
        <option value="2021">2021</option>
        <option value="2022">2022</option>
        <option value="2023">2023</option>
      </select>

      <select class="challengeWhellSelect" v-model="monthSelected">
        <option :value="item" v-for="(item, index) in 12" :key="index">{{item}}</option>
      </select>
      <button class="query" @click="queryVote">查询</button>
    </div>

    <div class="satisfaction">
      <!-- <div>
        <span>满意<b>（{{satisfieNum}}）</b></span>
        <div class="percent">{{satisfiePercent}}</div>
      </div>
      
      <div>
        <span>不满意<b>（{{disatisfieNum}}）</b></span>
        <div class="percent">{{disatisfiePercent}}</div>
      </div> -->
<!--      <div style="width: 100%;height: 150px; background-color:#F1F3F4;" ref="pie"></div>-->

    </div>
    <div class="total"><span style="display: inline-block;float:left;font-weight: bold">平均分数：{{Number(averageScore).toFixed(2) }}</span>参与总人数：{{totalPersonal}}</div>
  </main>

  <div class="vote-list" @scroll="scrollEvent">
    <ul class="vote-content">
      <template v-if="voteList.length>0">
        <li v-for="(item, index) in voteList" :key="index">
          <div style="width: 100%;">
<!--            <span class="vote-title" :class="item.satisfactionType==1?'title-color':''">{{item.satisfactionType==1?'满意':'不满意'}}</span>-->
            <div class="vote-time" :class="item.flag?'iszan':''" @click="onStar(item.id, index)">
              <img src="@/assets/img/star/icon95.png" alt="" v-if="item.flag">
              <img src="@/assets/img/star/icon43.png" alt="" v-else>
              <span>{{item.likeCount}}</span>
            </div>
            <div class="vote-remark" v-if="item.remark">{{item.remark}}</div>
            <div class="vote-remark" v-else style="color: #ccc;">无意见</div>
          </div>

        </li>
      </template>
      <li v-else>暂无评论</li>
    </ul>
  </div>

  <loading :options="loadOption" v-if="loading"></loading>

</template>

<script lang="ts">
  import { defineComponent, inject, onMounted, ref} from "vue";
  import { useRouter, useRoute } from "vue-router";
  import $ from "jquery";
  import axios from "@/api/axios";
  import qs from "qs";
  import Toast from "@/components/UI/Toast";
  import Modal from "@/components/UI/Modal";
  import Loading from "@/components/UI/Loading/src/Loading.vue";
  import * as echarts from 'echarts';
  import { parthrottle } from '@/utils/utils'
  export default defineComponent({
    name: "VoteView",
    components: {
      Loading
    },
    setup() {
      const satisfieNum = ref(0)
      const satisfiePercent = ref('0%')
      const disatisfieNum = ref(0)
      const disatisfiePercent = ref('0%')

      const yearSelected = ref('')
      const monthSelected = ref('')
      const page = ref(1)
      const isEnd = ref(false)
      const totalNum = ref()
      const totalPersonal = ref()
      const averageScore = ref()

      const voteList = ref<object>([])
      const router = useRouter();
      const route = useRoute();
      const pie = ref()
      //  展示用户信息
      const userInfo = inject("userInfo") as any;

      const loading = ref(false);
      const loadOption = {
        text: "正在加载...",
        color: "#df0024",
        textColor: "#df0024",
      };

      onMounted(function() {
        if (!(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
          window.location.href = 'https://www.bncc.com/User/CanteenVotingStatistics.html'
        }

        $('.home-wapper').css('padding-top', '0')
        const height = Number($(window).height()) - Number($('header').outerHeight())-Number($('main').outerHeight()) + 'px'
        $('.vote-list').css({'height': height})

        const token = localStorage.getItem("token");
        if (!token) {
          Modal({
            title: "温馨提示",
            content: "登录后才可以进行投票！",
            onClose: () => {
              router.push({
                name: "Login",
                query: {
                  redirect: router.currentRoute.value.fullPath,
                },
              });
            },
          });
          return false;
        }

        loading.value = true;
        axios
                .get("/M/UserServer/canteenVotingStatistics")
                .then(res => {
                  loading.value = false;
                  if (res.data.success) {
                    const data = res.data.obj;
                    totalNum.value = data.isSatisfactionCount + data.noSatisfactionCount
                    totalPersonal.value = data.totalPersonal
                    averageScore.value = data.averageScore
                    satisfieNum.value = data.isSatisfactionCount
                    disatisfieNum.value = data.noSatisfactionCount
                    satisfiePercent.value = data.isSatisfactionCountPercent
                    disatisfiePercent.value = data.noSatisfactionCountPercent
                    voteList.value = (voteList.value as any).concat(data.canteenVotingRecordList)
                    yearSelected.value = data.year
                    monthSelected.value = data.month

                    const myChart = echarts.init(pie.value);
                    myChart.setOption({
                      tooltip: {
                        trigger: 'item',
                        formatter: "数量 : {c} <br/>{b} : {d}%"
                      },
                      legend: {
                        bottom: 10,
                        orient: 'vertical',
                        right: 'right',
                        data: ['满意', '不满意']
                      },
                      series: [
                        {
                          name: '餐厅投票',
                          type: 'pie',
                          radius: '90%',
                          label:{            //饼图图形上的文本标签
                            normal:{
                              show: true,
                              position:'inner', //标签的位置
                              textStyle : {
                                fontWeight : 300 ,
                                fontSize : 14,    //文字的字体大小
                                color: '#fff'
                              },
                              formatter:'{d}%'
                            }
                          },
                          data: [
                            {
                              value: data.isSatisfactionCount,
                              name: '满意',
                              itemStyle:{
                                normal:{color:'#d64b4b'}
                              }
                            },
                            {
                              value: data.noSatisfactionCount,
                              name: '不满意',
                              itemStyle:{
                                normal:{color:'#7AB7F5'}
                              }
                            }
                          ],
                          emphasis: {
                            itemStyle: {
                              shadowBlur: 10,
                              shadowOffsetX: 0,
                              shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                          }
                        }
                      ]
                    });
                  } else {
                    Toast({
                      type: "error",
                      title: res.data.msg,
                      onClose: () => {
                        router.push({
                          name: "VoteTest"
                        });
                      },
                    });
                  }

                })
                .catch(err => {
                  console.log(err);
                });
      })

      function queryVote() {
        const data = qs.stringify({
          year: yearSelected.value,
          month: monthSelected.value
        });

        axios
                .post("/M/UserServer/canteenVotingStatistics", data, {
                  params: {
                    validate: true,
                  },
                })
                .then((res) => {
                  if (res.data.success) {
                    const data = res.data.obj;
                    satisfieNum.value = data.isSatisfactionCount
                    disatisfieNum.value = data.noSatisfactionCount
                    totalNum.value = data.isSatisfactionCount + data.noSatisfactionCount
                    totalPersonal.value = data.totalPersonal
                    averageScore.value = data.averageScore
                    satisfiePercent.value = data.isSatisfactionCountPercent
                    disatisfiePercent.value = data.noSatisfactionCountPercent
                    voteList.value = data.canteenVotingRecordList
                    yearSelected.value = data.year
                    monthSelected.value = data.month
                    const myChart = echarts.init(pie.value);
                    myChart.setOption({
                      tooltip: {
                        trigger: 'item',
                        formatter: "数量 : {c} <br/>{b} : {d}%"
                      },
                      legend: {
                        bottom: 10,
                        orient: 'vertical',
                        right: 'right',
                        data: ['满意', '不满意']
                      },
                      series: [
                        {
                          name: '餐厅投票',
                          type: 'pie',
                          radius: '90%',
                          label:{            //饼图图形上的文本标签
                            normal:{
                              show: true,
                              position:'inner', //标签的位置
                              textStyle : {
                                fontWeight : 300 ,
                                fontSize : 14,    //文字的字体大小
                                color: '#fff'
                              },
                              formatter:'{d}%'
                            }
                          },
                          data: [
                            {
                              value: data.isSatisfactionCount,
                              name: '满意',
                              itemStyle:{
                                normal:{color:'#d64b4b'}
                              }
                            },
                            {
                              value: data.noSatisfactionCount,
                              name: '不满意',
                              itemStyle:{
                                normal:{color:'#7AB7F5'}
                              }
                            }
                          ],
                          emphasis: {
                            itemStyle: {
                              shadowBlur: 10,
                              shadowOffsetX: 0,
                              shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                          }
                        }
                      ]
                    });
                  } else {
                    Toast({
                      type: "error",
                      title: res.data.msg,
                    });
                  }
                })
                .catch((err) => {
                  console.log(err)
                });

      }

      const scrollEvent = parthrottle(function(e) {
        if (e.srcElement.scrollTop + e.srcElement.offsetHeight + 200 >= e.srcElement.scrollHeight) {
          if(isEnd.value) {
            Toast({
              type: "error",
              title: '已经到底了！',
            });
            return false
          }
          loading.value = true;
          page.value++
          const data = qs.stringify({
            page: page.value
          });

          axios
                  .post("/M/UserServer/canteenVotingRecordListMore", data, {
                    params: {
                      validate: true,
                    },
                  })
                  .then((res) => {
                    loading.value = false;
                    if (res.data.success) {
                      const data = res.data.obj;
                      if (data.length>0) {
                        voteList.value = (voteList.value as any).concat(data)
                      } else {
                        page.value--
                        isEnd.value = true
                        Toast({
                          type: "error",
                          title: '已经到底了！',
                        });
                      }

                    } else {
                      Toast({
                        type: "error",
                        title: res.data.msg,
                      });
                    }
                  })
                  .catch((err) => {
                    console.log(err)
                  });
        }
      }, 400)


      // function scrollEvent(e: any) {
      //   if (e.srcElement.scrollTop + e.srcElement.offsetHeight >= e.srcElement.scrollHeight) {
      //     if(isEnd.value) {
      //         Toast({
      //             type: "error",
      //             title: '已经到底了！',
      //           });
      //         return false
      //     }
      //     loading.value = true;
      //     page.value++
      //     const data = qs.stringify({
      //       page: page.value
      //     });

      //      axios
      //     .post("/M/UserServer/canteenVotingRecordListMore", data, {
      //       params: {
      //         validate: true,
      //       },
      //     })
      //     .then((res) => {
      //       loading.value = false;
      //       if (res.data.success) {
      //         const data = res.data.obj;
      //         if (data.length>0) {
      //           voteList.value = voteList.value.concat(data)
      //         } else {
      //           page.value--
      //           isEnd.value = true
      //           Toast({
      //             type: "error",
      //             title: '已经到底了！',
      //           });
      //         }

      //       } else {
      //         Toast({
      //           type: "error",
      //           title: res.data.msg,
      //         });
      //       }
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //     });
      //   }
      // }

      function back() {
        router.back();
      }

      function onStar(id: any, index: any) {
        const data = qs.stringify({
          id
        });
        loading.value = true;
        axios
                .post("/M/UserServer/doCanteenVotingRecordLike", data, {
                  params: {
                    validate: true
                  }
                })
                .then(res => {
                  loading.value = false;
                  if (res.data.success) {
                    if(voteList.value[index].flag) {
                      voteList.value[index].likeCount -=1
                      voteList.value[index].flag = false
                    } else {
                      voteList.value[index].likeCount +=1
                      voteList.value[index].flag = true
                    }
                  }
                })

      }

      return {
        userInfo,
        satisfieNum,
        disatisfieNum,
        satisfiePercent,
        disatisfiePercent,
        voteList,
        yearSelected,
        monthSelected,
        queryVote,
        scrollEvent,
        loading,
        loadOption,
        back,
        pie,
        totalNum,
        totalPersonal,
        averageScore,
        onStar
      }
    },
  });
</script>

<style lang="scss" scoped>
  header {
    width: 375px;
    height: 55px;
    box-sizing: border-box;
    position: fixed;
    padding: 0 10px;
    padding-left: 20px;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    background-color: #fff;
    z-index: 10;
    border-bottom: 1px solid #eee;
  }
  header .back {
    width: 20px;
    height: 20px;
  }
  header .title {
    width: 8rem;
    font-size: 18px;
    text-align: center;
  }
  .logo {
    width: 27px;
    height: 27px;
    margin: 0;
  }
  .logo a {
    display: block;
    width: 27px;
    height: 27px;
  }
  .logo img {
    width: 27px;
    height: 27px;
    display: block;
  }

  main {
    margin-top: 55px;
    padding: 20px;

    // position: fixed;
    // background: #fff;
    // width: 100%;
    // top: 55px;
  }

  main .search {
    display: flex;
    justify-content: space-between;
  }

  main .search select {
    font-size: 14px;
    color: #333;
  }

  .challengeWhellSelect {
    width: 1.6rem;
    width: 35%;
    height: 0.5rem;
    height: 0.73333rem;
    background: none;
    font-family: 'Microsoft YaHei';
    border: 1px solid #eee;
  }

  .query {
    // margin-top: 30px;
    // margin-left: 10%;
    width: 1.93333rem;
    height: 0.73333rem;
    // width: 60px;
    // height: 1.2rem;
    background-color: #d64b4b;
    box-shadow: 0 0.13333rem 0.26667rem 0 rgb(0 0 0 / 30%);
    border-radius: .1rem;
    line-height: 0.73333rem;
    text-align: center;
    color: #fff;
    border: none;
    outline: none;
    font-size: .4rem;
    padding: 0;
  }

  .satisfaction {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-top: 20px;
  }
  .satisfaction span b {
    color: #D64B4B;
  }

  .satisfaction .percent {
    color: #D64B4B;
    font-size: 14px;
    margin-top: 5px;
  }

  .vote-list {
    padding: 0 20px 20px 20px;
    // position: fixed;
    // bottom: 0;
    width: 100%;
    overflow-y: auto;
    word-wrap:break-word;
    word-break:break-all;
  }

  // .vote-content {
  //   overflow-y: auto;
  // }

  .vote-content li {
    font-size: .42667rem;

    padding: 10px 0;
    border-top: 1px solid #eee;

    display: flex;
    flex-wrap: wrap;
  }

  .vote-content .vote-title {
    color: #D64B4B;
  }

  .vote-content .title-color {
    color: #009688;
  }

  .vote-content .vote-time {
    position: relative;
    top: 2px;
    float: right;
    color: #888;
    font-size: 14px;
    img {
      width: 15px;
      height: 15px;
      vertical-align: middle;
      margin-right: 5px;
      position: relative;
      // top: -2px;
    }
    span {
      position: relative;
      top: 3px;
    }
  }
  .iszan {
    color: #df0024!important;
  }

  .vote-content .vote-remark {
    margin-top: 10px;
  }
  .total {
    background-color:#F1F3F4;
    font-size: 14px;
    color: #888;
    padding: 0 6px 10px 10px;
    text-align: right;
    height: 40px;
    line-height: 40px;
  }
</style>